import React, { FunctionComponent } from 'react';
import '@dotpe/kui';
import styles from '../../WidgetMaker/WidgetDnD/widgetDnD.module.scss';
import { CreateHTML } from '../../WidgetMaker/WidgetDnD/CreateHTML';
import { useSSRSelector } from '@/redux/ssrStore';

interface JsonPageWidgetProps {
  template: string;
  widget_id?: string;
  widgetProps?: Record<string, any>;
  widgets?: any;
  storeData?: any;
}

export const _JsonPageBuilderWidget: FunctionComponent<JsonPageWidgetProps> = () => {
  const jsonTree = useSSRSelector((state) => state.jsonWidgetsReducer.widgets);
  return (
    <div id="page-builder-view" className={styles.builderPage}>
      <CreateHTML jsonTree={jsonTree} />
    </div>
  );
};

// const areEqual = (prevProps: JsonPageWidgetProps, nextProps: JsonPageWidgetProps) => {
//   if (prevProps.widget_id === nextProps.widget_id) {
//     return true;
//   }
//   return false;
// };

// export const JsonPageBuilderWidget = React.memo(_JsonPageBuilderWidget, areEqual);
export const JsonPageBuilderWidget = _JsonPageBuilderWidget;
